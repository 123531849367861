import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import {
    Container,
    Row,
    Col,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
  } from 'reactstrap';


export default class FunctionalitySection extends Component{

  render(){

    const { link } = this.props;
    return(
      <div className="section">
        <Container className="functionnalityContainer">
        {/* <iframe src="https://giphy.com/embed/8L0OMY9Kj1N5u6wdkL" width="480" height="270" frameBorder="0" className="giphy-embed" allowFullScreen>
        </iframe> */}
          <h2 className="title">
            Présentation
          </h2>
          <Row>
            <Col xs={12}>
              <Slide bottom>
                <div className="videoResponsive">
                  <iframe title={`Presentation`} width="560" height="315" src="https://www.youtube.com/embed/T3LJFQSbHRg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowFullScreen>
                  </iframe>
                </div>
              </Slide>
            </Col>
          </Row>
          <p className="lead">
          Le plugin TriDyme pour Archicad et Revit permet aux Architectes de faire une <strong>analyse structurelle en phase amont</strong> de leur projet sans nécessité de recourir à un bureau d'études à ce stade du projet.
          </p>
          <p className="lead">
            Cela leur permet également de minimiser le risque de reprendre certains aspects du projet une fois que la consultation d'un bureau d'étude devient nécessaire.
          </p>
        </Container>
      </div>            
    )
  }
}