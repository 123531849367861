import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
	Container,
	Row,
	Col,
	InputGroup,
	InputGroupAddon,
	Button,
	Media,
	Label
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
import AvantagesSvg from './img/Avantages.svg';
import StructurePng from './img/Architecture1.png';

const Advantages = ({
	link
}) => {
	return (
		<div className="section">
			<Container>
				<Row>
					<Col lg={6}>
						<Fade left>
							<div className="imgContainer">
								<img className="imgContent" src={StructurePng} alt="Sections" />
							</div>
						</Fade>
					</Col>
					<Col lg={6}>
						<Row>
							<Fade right cascade>
								<Media className="app">
									<Media left href="#">
										<FormattedMessage id="section_alt">
											{
												alt => (
													<img
														className="icon"
														src={AvantagesSvg}
														alt={alt}
													/>
												)
											}
										</FormattedMessage>
									</Media>
									<Media body>
										<Media heading>
											<h2 className="label">
												Les Avantages
											</h2>
										</Media>
										{/* <Label className="subTitle">
											Architecture
										</Label> */}
									</Media>
								</Media>
							</Fade>
						</Row>
						<Row>
							<Fade right>
								<p className="lead">
									Pour les Maitres d'ouvrage, la mise en place de sa plateforme cloud personnalisée :
								</p>
							</Fade>
							<Fade right cascade>
								<ul className="lead">
									<li>
										Gérer l'exploitation et la maintenance de son parc d'actif
									</li>
									<li>
										Pouvoir disposer d'un jumeau numérique connecté à des capteurs intelligents permettant de faire de la maintenance prédictive
									</li>
									<li>
										Disposer d'un référentiel pour ses actifs de la conception à l'exploitation
									</li>
									<li>
										Piloter l'avancement des projets en s'appuyant sur les données techniques en temps réel
									</li>
								</ul>
							</Fade>
							{/* <Fade right cascade>
								<ul className="lead">
									<li>
										Travailler en BIM
									</li>
									<li>
									 <strong> Bénéficier de la puissance d'un logiciel</strong> classique, <strong> accessible en ligne</strong>
									</li>
									<li>
										Modifier un document sans difficulté
									</li>
									<li>
										<strong>Partager la maquette </strong> avec plusieurs de ses collaborateurs
									</li>
									<li>
										Recevoir instantanément les modifications effectuées par chacun
									</li>
									<li>
										Accéder au document à volonté
									</li>
									<li>
										<strong>Travailler facilement à distance</strong>
									</li>
									<li>
									 Eviter les doublons de documents
									</li>
								</ul>
							</Fade>*/}
						</Row>
						<Row>
							<Col lg={12}>
								<Fade right>
									<Button
										href={link}
										className="negativeButton"
									>
										Essayez
									</Button>
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Advantages;

