import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import MaitresOuvrage from '../scenes/LandingPages/MaitresOuvrage';
import HeadMeta from '../components/HeadMeta';

const {
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | Contractors`,
    description: "Structural Analysis for Contractors"
  },
  fr: {
    title: `TriDyme | Maitres d'Ouvrage`,
    description: "TriDyme optimise les maquettes d'architecture dès la phase esquisse"
  }
};

const LandingPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta
      locale={locale}
      metaData={metaData}
    />
    <MaitresOuvrage />
  </Layout>
)

LandingPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default LandingPage;