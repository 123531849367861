import React, { Component } from "react";
import Header from "./scenes/Header";
import FonctionalitySection from "./scenes/FonctionalitySection";
import ReferenceSection from "./scenes/ReferenceSection";
import Advantages from "./scenes/Advantages";
import Fonctionnement from "./scenes/Fonctionnement";
import Presentation from "./scenes/Presentation";
import UseCases from "./scenes/UseCases";
import VisualisationAndControl from "./scenes/VisualisationAndControl";

const { REACT_APP_URL } = process.env;

export default class Home extends Component {
  render() {
    const linkToApp = "https://app.tridyme.com";
    return (
      <div>
        {/* <Header link={linkToApp} /> */}
        <Presentation link={linkToApp} />
        <UseCases link={linkToApp} />
        <Advantages link={linkToApp} />
        <VisualisationAndControl link={linkToApp} />
        {/* <FonctionalitySection link={linkToApp} />
        <Advantages link={linkToApp} />
        <Fonctionnement link={linkToApp} />
        <ReferenceSection link={linkToApp} /> */}
      </div>
    );
  }
}
