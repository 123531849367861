import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab
} from '@material-ui/core';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VisualisationAndControl() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="md" className="section">
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant='h2' components='h2' className="title">
            Visualisation et Contrôle
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto', width: '100%' }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab className='lead' label="Visualisez un grand nombre de maquettes directement en ligne" {...a11yProps(0)} />
              <Tab className='lead' label="Possibilité de faire des coupes, d’avoir des vues en plans et de les exporter en .dxf ou .pdf" {...a11yProps(1)} />
              <Tab className='lead' label="Outils de cotation pour mesurer facilement les distances" {...a11yProps(2)} />
              <Tab className='lead' label="Outils de gestion de tâches" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              Item One
      </TabPanel>
            <TabPanel value={value} index={1}>
              Item Two
      </TabPanel>
            <TabPanel value={value} index={2}>
              Item Three
      </TabPanel>
            <TabPanel value={value} index={3}>
              Item Four
      </TabPanel>
            <TabPanel value={value} index={4}>
              Item Five
      </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Container >
  );
}