import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core';


const UseCases = ({
  link
}) => {
  return (
    <Container maxWidth="md" className="section">
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant='h2' components='h2' className="title">
            Cas d'usages
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <p className="lead">
            Les cas d'usages sont les suivants:
            </p>
        </Grid>
        <Grid item xs={12}>
          <ul>
            <li>
              <p className="lead">
                Avoir une vue d’ensemble sur le projet et l’historique de ses évolutions de maquette
      </p>
            </li>
            <li>
              <p className="lead">
                Disposer d’un référentiel du projet, base de données IFC de la conception à l’exploitation
      </p>
            </li>
            <li>
              <p className="lead">
                Piloter l’avancement de vos projets en s’appuyant sur les données techniques en temps réel
      </p>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Container >
  )
}

export default UseCases;