import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core';


const Presentation = ({
  link
}) => {
  return (
    <Container maxWidth="md">
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant='h2' components='h2' className="title">
            Présentation
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Slide bottom>
            <div className="videoResponsive">
              <iframe title={`Presentation`} width="560" height="315" src="https://www.youtube.com/embed/T3LJFQSbHRg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowFullScreen>
              </iframe>
            </div>
          </Slide>
        </Grid>
        <Grid item xs={12}>
          <p className="lead">
            TriDyme permet aux Maîtres d’Ouvrages de disposer d’une Plateforme Centralisée accessibles directement en ligne et permettant de gérer leurs projets de la phase conception à la phase de gestion, exploitation, maintenance en temps réel.
      </p>
        </Grid>
        <Grid item xs={12}>
          <p className="lead">
            Véritable vecteur de communication au sein de votre projet de construction, la plateforme collaborative BIM est l’outil essentiel pour interagir avec la maquette numérique de manière simple.
      </p>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Presentation;
